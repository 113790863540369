import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Center } from '../../../../../core/center/center.model';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { Subscription } from 'rxjs';
import { CenterService } from '../../../../../core/center/center.service';
import { LayoutUtilsService , MessageType } from '../../../../../core/_base/crud/utils/layout-utils.service';
import { UserEditComponent } from '../../../user-management/users/user-edit/user-edit.component';
import { CenterEditComponent } from '../../../training-center-management/center-edit/center-edit/center-edit.component';
import { VacanciesService } from '../../../../../core/vacancy/vacancies.service';
import { EditPostingComponent } from '../../postings/edit-posting/edit-posting.component';
import { RepostOfficerComponent } from '../../postings/repost-officer/repost-officer.component';
// import { CenterEditComponent } from '../../../.center-edit/center-edit/center-edit.component';
import Swal from 'sweetalert2'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
	User,
	UserUpdated,
	Address,
	SocialNetworks,
	selectHasUsersInStore,
	selectUserById,
	UserOnServerCreated,
	selectLastCreatedUserId,
	selectUsersActionLoading
} from '../../../../../core/auth';
import { Clearance } from '../../../../../core/vacancy/clearance.model';
import { SelectionModel } from '@angular/cdk/collections';
import { element } from 'protractor';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import * as XLSX from 'xlsx';
@Component({
  selector: 'kt-mdcn-monthly-clearance',
  templateUrl: './mdcn-monthly-clearance.component.html',
  styleUrls: ['./mdcn-monthly-clearance.component.scss']
})
export class MdcnMonthlyClearanceComponent implements OnInit {
  exportData:any;
  statsList:[] = [];	
  user:User;
  searchKey:string;
  clearanceForm:FormGroup;
  centerId:number;
  month:string;
  year:string;
  officer:number;
  cDetails:any;
  hasFormErrors = false;
	centers :any;
	allcleared:any;
	stats:any;
	@BlockUI() blockUI: NgBlockUI;
	
	// Table fields

	listData: MatTableDataSource<Clearance>;
	selection = new SelectionModel<Clearance>(true, []);


	displayedColumns = ['select','centerName', 'folioNumber','firstName','lastName',  'batch', 'year','month', 'status','paid_so_far', 'actions' ];

	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild('sort1', {static: true}) sort: MatSort;
	// Filter fields
	@ViewChild('searchInput', {static: true}) searchInput: ElementRef;
	
	// Subscriptions
	private subscriptions: Subscription[] = [];

  constructor(
    private centerService: CenterService,
    private vacancyService: VacanciesService,
    private layoutUtilsService: LayoutUtilsService,
    private dialog: MatDialog,
    private clearanceFB:FormBuilder
  ) { }

  ngOnInit() {
 
		// load allusers 
    
    this.createForm();
    

    this.getAllCenters();

   
		
		

	
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}

	/**
	 * Load users list
	 */
  
   

  createForm() {
		// this.center.centerName = this.cName
		// this.center.slogan = this.slogan
		//  this.center.tel = this.tel
		//  this.center.email = this.email
		//  this.center.address = this.address
		//  this.center.stateId = this.stateId
		//  this.center.cityId = this.cityId
		// this.getCitiesForState(this.stateId)
		
		this.clearanceForm = this.clearanceFB.group({
	
			centerId: ['',Validators.required],
			month: ['', Validators.required],
			year: ['', Validators.required]
		//	officer: [this.officer, Validators.required],
		//	jwt:[this.user.jwt],
		
			
		});
	}
	 /** Whether the number of selected elements matches the total number of rows. */
	 isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listData.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
				this.listData.data.forEach(row => this.selection.select(row));
				
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Clearance): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
	}
	fetchUsers() {
		const messages = [];
		this.selection.selected.forEach(elem => {
			messages.push({
				text: `${elem.firstName}, ${elem.folioNumber}`,
				id: elem.id.toString(),
			
				status: elem.status
			});
		});
	
		this.layoutUtilsService.fetchElements(messages);
	}
	payUsers() {
		const clerance = [];
		this.selection.selected.forEach(elem => {
			clerance.push({
				text: `${elem.folioNumber}`,
				id: elem.cleranceId,
				userId:elem.userId,
				officer:elem.officer,
				folioNumber:elem.folioNumber,
				centerId:elem.centerId,
				status:elem.status

				
			});
		});
	//alert(JSON.stringify(clerance))
		this.onBulkCleared(clerance)
	}


  onSumbit(withBack: boolean = false) {
		this.hasFormErrors = false;
		const controls = this.clearanceForm.controls;
		/** check form */
		if (this.clearanceForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			//this.selectedTab = 0;
			return;
    }
    

    const editedClerance = this.prepareClerance();
    this.getAllClearance(editedClerance.centerId,editedClerance.month,editedClerance.year)

    
    
  
		
		// if (editedCenter.id > 0) {
		// 	this.updatePosting(editedCenter, withBack);
		// 	return;
		// }

		// this.addPosting(editedCenter, withBack);
  }
  
  prepareClerance(): Clearance {
		const controls = this.clearanceForm.controls;
		const _clerance = new Clearance();
		_clerance.clear();
		
		

    _clerance.centerId =controls.centerId.value;
    _clerance.month= controls.month.value;
		_clerance.year = controls.year.value;
		
    
	
		return  _clerance;
	}
	/** FILTRATION */
	filterConfiguration(): any {
		const filter: any = {};
		const searchText: string = this.searchInput.nativeElement.value;

		filter.lastName = searchText;

		filter.username = searchText;
		filter.email = searchText;
		filter.fillname = searchText;
		return filter;
	}

	/** ACTIONS */
	/**
	 * Delete user
	 *
	 * @param _item: User
	 */
	deleteUser(_item: Center) {
		const _title = 'Center Delete';
		const _description = 'Are you sure to permanently delete this center?';
		const _waitDesciption = 'Center is deleting...';
		const _deleteMessage = `Center has been deleted`;

		const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}

		//	this.store.dispatch(new UserDeleted({ id: _item.id }));
			this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
		});
	}

	

	/* UI */
	/**
	 * Returns user roles string
	 *
	 * @param user: User
	 */

	/**
	 * Redirect to edit page
	 *
	 * @param id
	 */
	editUser(id) {
    
	}
	ViewReason(clerance) {
		const messages = [];
		
			messages.push({
				text: `${clerance.reason}`,
				id: clerance.id.toString(),
			
				status: clerance.status
			});
		
	
		this.layoutUtilsService.fetchElements(messages);
	}
	exportExcel() {
		const workSheet = XLSX.utils.json_to_sheet(this.exportData, {header:['Housemanship Monthly Clearance']});
	//	const workSheet2 = XLSX.utils.json_to_sheet(this.statsList, {header:['Housemanship Monthly Clearance Statistics']});
       
		const workBook: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workBook, workSheet, 'Clearance');
		//XLSX.utils.book_append_sheet(workBook, workSheet2, 'Clearance_Stats');
        var fileName = "Housemanship_monthly_clearance"+ Date.now()+".xlsx";
		XLSX.writeFile(workBook, fileName);
	}
	getAllClearance(centerId,month,year){
		this.blockUI.start('Fetching clearance...Please wait')
		this.vacancyService.getCleranceStatus(centerId,month,year).subscribe(
		
			list=>{
				let array = list.data.map(item =>{
					return item
				})
		this.blockUI.stop()
		
				this.allcleared = list.data;
				this.exportData = list.reportData
			//	this.statsList.push(this.stats)
				this.stats = list.stat;
        this.listData = new MatTableDataSource(array)
       
        
				this.listData.sort = this.sort
				this.listData.paginator = this.paginator
			
				
      },
      error=>{
				this.blockUI.stop()
        Swal.fire(
          'Oops',
          error.error.message,
          'error'
          )

      }
			)
	}
	
	refreshAllClearance(centerId,month,year){
	
		this.vacancyService.getCleranceStatus(centerId,month,year).subscribe(
		
			list=>{
				let array = list.data.map(item =>{
					return item
				})
       
				this.allcleared = list.data;
				this.stats = list.stat;
        this.listData = new MatTableDataSource(array)
       
        
				this.listData.sort = this.sort
				this.listData.paginator = this.paginator
			
				
      },
      error=>{
				this.blockUI.stop()
        Swal.fire(
          'Oops',
          error.error.message,
          'error'
          )

      }
			)
  }


  getCenterDetails(){
return this.getAllCenters()
  }

  getAllCenters(){
		this.centerService.getALLCenters().subscribe(data=>{
      this.centers = data
	 // this.centerId =  this.centers[0].id
	  //this.centerId =  50;

      this.getAllClearance(this.centers[0].id,"January","2021")
    
     

    }
		
			)

	}

	applyFilter(){
		
		this.listData.filter = this.searchKey.trim().toLowerCase();
  }
  
  onCreate(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    this.dialog.open(EditPostingComponent,dialogConfig )
  }
  onRepost(){
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    this.dialog.open(RepostOfficerComponent,dialogConfig )
  }
 onEdit(center,id) {
	
	const dialogConfig = new MatDialogConfig();
	dialogConfig.disableClose = true;
	dialogConfig.autoFocus = true;
	dialogConfig.width = "60%";
	dialogConfig.data= center;
	this.dialog.open(EditPostingComponent,dialogConfig)
	//this.router.navigate(['../users/edit', id], { relativeTo: this.activatedRoute });
}
onMonthlyCleared(center){

  this.hasFormErrors = false;
  const controls = this.clearanceForm.controls;
  /** check form */
  if (this.clearanceForm.invalid) {
    Object.keys(controls).forEach(controlName =>
      controls[controlName].markAsTouched()
    );

    this.hasFormErrors = true;
    //this.selectedTab = 0;
    return;
  }
  

  const editedClerance = this.prepareClerance();
 

  Swal.fire({
    title: 'Are you sure you want to pay'+center.firstName+ ' for '+editedClerance.month,
    text: 'the process is irreversible',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, I want to pay the Officer',
    cancelButtonText: 'Cancel'
    }).then((result) => {
    if (result.value) {
      editedClerance.officer = center.officer
      editedClerance.id = center.cleranceId
			editedClerance.status = 'Paid';
			editedClerance.amount = 150000;
     
      
      this.clearOfficer(editedClerance)
     // this.postOfficer(controls.HouseManshipCenters.value)
    
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
      'Cancelled',
      'Clearance cancelled',
      'error'
      )
    }
    })
}


onBulkCleared(center){

  this.hasFormErrors = false;
  const controls = this.clearanceForm.controls;
  /** check form */
  if (this.clearanceForm.invalid) {
    Object.keys(controls).forEach(controlName =>
      controls[controlName].markAsTouched()
    );

    this.hasFormErrors = true;
    //this.selectedTab = 0;
    return;
  }
  

  const editedClerance = this.prepareClerance();
 

  Swal.fire({
    title: 'Are you sure you want to pay the selected officer for '+editedClerance.month,
    text: 'the process is irreversible',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, I want to pay the Officers',
    cancelButtonText: 'Cancel'
    }).then((result) => {
    if (result.value) {
			editedClerance.bulk=center
		
			

			
			editedClerance.amount = 150000
     
     
      
			this.clearOfficer(editedClerance)
      
    
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
      'Cancelled',
      'Clearance cancelled',
      'error'
      )
    }
    })
}
clearOfficer(clearance:Clearance){
	this.blockUI.start('Processing clearance...Please wait')
  this.vacancyService.PayOfficer(clearance).subscribe(data=>{
    Swal.fire(
      'Cleared',
      'Officer Successfully Paid',
      'success'
			)
			this.blockUI.stop();
    this.refreshAllClearance(clearance.centerId,clearance.month,clearance.year)
  },error=>{
		this.blockUI.stop();

    Swal.fire(
      'Oops',
      error.error.message,
      'error'
      )

  }
  )
}
 
}
