export class Clearance {
    id: number;
    centerName: string;
    centerId:number;
    userId:number;
    
    year:string;
    batch:number;
    batchName:string;
    folioNumber:string;
    month:string;
    email:string;
    firstName:string;
    lastName:string;
    jwt:string;
    status:string;
    officer:number;
    reason:string;
    amount:number;
    cleranceId:number;
    isCleared:boolean;
    bulk:[];
  

    clear(): void {
        this.id = undefined;
        this.centerName = '';
        this.userId = undefined;
        this.centerId = undefined;
        this.year='';
        this.month='';
        this.officer = undefined;
        this.reason = '';
        this.folioNumber = '';
        this.bulk = [];
        this.amount =undefined;
        this.cleranceId =undefined;
        this.isCleared= false;
       
        this.batch = undefined;
        this.batchName='';
        this.status='';
       

        this.jwt = localStorage.getItem('token')
       
       
    }



}