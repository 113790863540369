export class Posting {
    id: number;
    userId: number;
    centerName: string;
    centerId:number;
    year:string;
    batch:number;
    batchName:string;
    email:string;
    firstName:string;
    lastName:string;
    jwt:string;
    officer:number;
    isRepost:boolean;
    isReset:boolean;
    isAccepted:boolean;
    folioNumber:string;
    trainingType:number;
    lastcenter:number;
    lastposter:string;
    reason:string;
    accpter:string;
    pType:string;



    clear(): void {
        this.id = undefined;
        this.userId = undefined;
        this.centerName = '';
        this.centerId = undefined;
        this.year='';
        this.isRepost= false;
        this.isReset = false;
        this.isAccepted = false;
        this.folioNumber = '';
        this.reason = '';
        
        this.batch = undefined;
        this.batchName='';
        this.accpter = '';
        this.pType = undefined
       

        this.jwt = localStorage.getItem('token')
       
       
    }



}