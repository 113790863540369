// Angular
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// Components
import {BaseComponent} from './views/theme/base/base.component';
import {ErrorPageComponent} from './views/theme/content/error-page/error-page.component';
// Auth
import {AuthGuard} from './core/auth';
import { MyPostingComponent } from './views/pages/my-posting/my-posting.component';
import { MyPaymentsComponent } from './views/pages/my-payments/my-payments.component';
import { MyProfileComponent } from './views/pages/my-profile/my-profile.component';
import { MyEmailsComponent } from './views/pages/my-emails/my-emails.component';
import { TrainingCenterManagementComponent } from './views/pages/training-center-management/training-center-management.component';
import { VacanciesComponent } from './views/pages/vacancy-management/vacancies/vacancies.component';
import { PostingsComponent } from './views/pages/officer-management/postings/postings/postings.component';
import { CenterStaffComponent } from './views/pages/training-center-management/center-staff/center-staff.component';
import { MyAccountDetailsComponent } from './views/pages/my-account-details/my-account-details.component';
import { AuditTrailComponent } from './views/pages/audit-trail/audit-trail.component';
import { MonthlyClearanceComponent } from './views/pages/officer-management/clearance/monthly-clearance/monthly-clearance.component';
import { MdcnMonthlyClearanceComponent } from './views/pages/officer-management/clearance/mdcn-monthly-clearance/mdcn-monthly-clearance.component';
import { PostingCongifurationComponent } from './views/pages/posting-congifuration/posting-congifuration.component';
import { OfficerRegComponent } from './views/pages/auth/officer-reg/officer-reg.component';
import { OfficersAccountDetailsComponent } from './views/pages/officer-management/officers-account-details/officers-account-details.component';
import { PostingHistoryComponent } from './views/pages/officer-management/postings/posting-history/posting-history.component';
import { PostingResetHistoryComponent } from './views/pages/officer-management/postings/posting-reset-history/posting-reset-history.component';
import { HouseOfficersComponent } from './views/pages/officer-management/house-officers/house-officers.component';
import { BatchConfigurationComponent } from './views/pages/batch-configuration/batch-configuration.component';
import { HouseOfficersAwaitingPostingComponent } from './views/pages/officer-management/house-officers-awaiting-posting/house-officers-awaiting-posting.component';
import { HouseOfficersAwaitingAcceptanceComponent } from './views/pages/officer-management/house-officers-awaiting-acceptance/house-officers-awaiting-acceptance.component';
import { HouseOfficersUnderTrainingComponent } from './views/pages/officer-management/house-officers-under-training/house-officers-under-training.component';
import { HouseOfficersGraduatedComponent } from './views/pages/officer-management/house-officers-graduated/house-officers-graduated.component';
import { FeedbackComponent } from './views/pages/feedback/feedback.component';
import { FeedbackManagementComponent } from './views/pages/feedback-management/feedback-management.component';
import { FeedbackReceiverComponent } from './views/pages/feedback-receiver/feedback-receiver.component';
import { BatchManagementComponent } from './views/pages/batch-management/batch-management.component';


const routes: Routes = [
	{path: 'auth', loadChildren: () => import('app/views/pages/auth/auth.module').then(m => m.AuthModule)},
	{path: '', loadChildren: () => import('app/views/pages/auth/auth.module').then(m => m.AuthModule)},

	{
		path: 'main',
		component: BaseComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'house-officers-graduated',
				component:HouseOfficersGraduatedComponent
			},
			{
				path: 'house-officers-under-training',
				component:HouseOfficersUnderTrainingComponent
			},
			{
				path: 'house-officers-awaiting-acceptance',
				component:HouseOfficersAwaitingAcceptanceComponent
			},
			{
				path: 'house-officers-awaiting-posting',
				component:HouseOfficersAwaitingPostingComponent
			},
			{
				path: 'my-posting',
				component: MyPostingComponent
			},
			{
				path: 'my-payments',
				component: MyPaymentsComponent
			},
			{
				path: 'my-profile',
				component: MyProfileComponent
			},
			{
				path: 'my-emails',
				component: MyEmailsComponent
			},
			{
				path: 'center-management',
				component: TrainingCenterManagementComponent
			},
			
			{
				path: 'vacancy-management',
				component: VacanciesComponent
			},
			{
				path:'postings',
				component: PostingsComponent
			},
			{
				path:'training-center-staff',
				component: CenterStaffComponent
			},
			{
				path:'my-account-details',
				component: MyAccountDetailsComponent
			},
			{
				path:'audit-trail',
				component: AuditTrailComponent
			},

			{
				path:'clearance',
				component:MonthlyClearanceComponent
			},

			{
				path:'mdcn-clearance',
				component:MdcnMonthlyClearanceComponent
			},
			{
				path:'posting-configuration',
				component:PostingCongifurationComponent
			},
			{
				path:'batch-configuration',
				component:BatchConfigurationComponent
			},
			{
				path:'batch-management',
				component:BatchManagementComponent
			},
			{
				path:'officer-account-details',
				component:OfficersAccountDetailsComponent
			},
			{
				path:'posting-history',
				component:PostingHistoryComponent
			},
			{
				path:'posting-reset-history',
				component:PostingResetHistoryComponent
			},
			{
				path:'house-officers',
				component:HouseOfficersComponent
			},
			{
				path:'feedback',
				component:FeedbackComponent
			},
			{
				path:'feedback-management',
				component:FeedbackManagementComponent
			},

			{
				path:'feedback-receivers',
				component:FeedbackReceiverComponent
			},

		
			{
				path: 'dashboard',
				loadChildren: () => import('app/views/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
			},
			{
				path: 'mail',
				loadChildren: () => import('app/views/pages/apps/mail/mail.module').then(m => m.MailModule),
			},
			{
				path: 'ecommerce',
				loadChildren: () => import('app/views/pages/apps/e-commerce/e-commerce.module').then(m => m.ECommerceModule),
			},
			{
				path: 'ngbootstrap',
				loadChildren: () => import('app/views/pages/ngbootstrap/ngbootstrap.module').then(m => m.NgbootstrapModule),
			},
			{
				path: 'material',
				loadChildren: () => import('app/views/pages/material/material.module').then(m => m.MaterialModule),
			},
			{
				path: 'user-management',
				loadChildren: () => import('app/views/pages/user-management/user-management.module').then(m => m.UserManagementModule),
			},
			{
				path: 'wizard',
				loadChildren: () => import('app/views/pages/wizard/wizard.module').then(m => m.WizardModule),
			},
			{
				path: 'builder',
				loadChildren: () => import('app/views/theme/content/builder/builder.module').then(m => m.BuilderModule),
			},
			{
				path: 'error/403',
				component: ErrorPageComponent,
				data: {
					type: 'error-v6',
					code: 403,
					title: '403... Access forbidden',
					desc: 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator',
				},
			},
			{path: 'error/:type', component: ErrorPageComponent},
			{path: 'main', redirectTo: 'dashboard', pathMatch: 'full'},
			{path: '**', redirectTo: 'dashboard', pathMatch: 'full'},
		],
	},

	{path: '**', redirectTo: 'error/403', pathMatch: 'full'},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {
}
