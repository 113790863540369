import { BaseModel } from '../../_base/crud';
import { Address } from './address.model';
import { SocialNetworks } from './social-networks.model';

export class User extends BaseModel {
    id: number;
    username: string;
    password: string;
    email: string;
    accessToken: string;
    refreshToken: string;
    roles: number[];
    pic: string;
    fullname: string;
    occupation: string;
    companyName: string;
    firstName: string;
    userType:string;
    lastName:string;
    otherName:string;
	phone: string;
    address: Address;
    folioNumber:string;
    mdcn_passport:string;
    registrationNumber:string;
    regType:string;
    regDate:any;
    practiceType:string;
    socialNetworks: SocialNetworks;
    centerId:number;
    role:number;
    oldPassword:string;
    previousName:string;
    provisionalExpiryDate:any;
    dateOfIssue:any;
    bvn:any;
    jwt:string;
    accountNumber:string;
    bankName:string;
    typeId:number;
    type:string;
    gender:string;
    institution:string;
    status:string;

    clear(): void {
        this.id = undefined;
        this.status='';
        this.username = '';
        this.password = '';
        this.email = '';
        this.roles = [];
        this.fullname = '';
        this.regType = '';
        this.centerId = undefined;
        this.accessToken = 'access-token-' + Math.random();
        this.refreshToken = 'access-token-' + Math.random();
        this.pic = '';
        this.occupation = '';
        this.companyName = '';
        this.jwt = localStorage.getItem('token')
        this.phone = '';
        this.role = undefined;
        this.folioNumber = '';
        this.registrationNumber = '';
        this.address = new Address();
        this.regDate = '';
        this.bvn = '';
        this.regType = '';
        this.bankName = '';
        this.accountNumber = '';
        this.practiceType = '';
        this.address.clear();
        this.socialNetworks = new SocialNetworks();
        this.socialNetworks.clear();
        this.typeId=undefined;
        this.type='';
        this.gender='';
        this.institution='';
    }
}
